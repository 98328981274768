import React, { useMemo } from 'react';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { MicrofrontendsRenderTreeManagerProvider } from 'src/contexts/microfrontendsRenderTreeManager';
import { ErrorBoundaryPropsType } from 'src/components/ErrorBoundary/types';
import { LogManagerProvider } from 'src/contexts/logManager';

const AppComponent: React.FC<T.AppPropsType> = ({
  children,
  errorHandler,
  assetReference,
  logger,
  firstComponentName,
  firstComponentDisplayName
}) => {
  const errorBoundary = useMemo<T.AppComponentDetailsMemoType>(() => {
    if (errorHandler?.enable) {
      const props: ErrorBoundaryPropsType = {
        showError: errorHandler?.showError
      };

      return {
        Component: ErrorBoundary,
        props
      };
    }
    return { Component: React.Fragment, props: {} };
  }, [errorHandler]);

  return (
    <MicrofrontendsRenderTreeManagerProvider
      assetReference={assetReference}
      firstComponentName={firstComponentName}
      firstComponentDisplayName={firstComponentDisplayName}
    >
      <LogManagerProvider {...logger}>
        <errorBoundary.Component {...errorBoundary?.props}>
          {children}
        </errorBoundary.Component>
      </LogManagerProvider>
    </MicrofrontendsRenderTreeManagerProvider>
  );
};

const App: React.FC<T.AppPropsType> = (props) => {
  return (
    <DependencyManagerProvider>
      <AppComponent {...props} />
    </DependencyManagerProvider>
  );
};

export default App;
